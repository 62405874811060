/* eslint arrow-parens: [0] */
/* eslint no-loop-func: [0] */
/* eslint max-len: [0, 90] */
/* eslint padded-blocks: [0] */
/* eslint-env browser,es6 */

function ready() {
  "use strict";

  let isTouch = ('ontouchstart' in document.documentElement);

  if (isTouch) {
    document.documentElement.classList.add('__touch');

    let domDropdowns = document.querySelectorAll('.__menu_main .dropdown');
    Array.prototype.forEach.call(domDropdowns, (domDropdown) => {

      let domArrowBtn = document.createElement('span');
      domArrowBtn.className = "__arrow_down";
      domArrowBtn.textContent = '▼';

      let domAnchor = domDropdown.firstElementChild;
      domAnchor.parentNode.insertBefore(domArrowBtn, domAnchor.nextSibling);

      domArrowBtn.addEventListener('click', () => {
        if (domDropdown.classList.contains('open')) {
          domDropdown.classList.remove('open');
          domArrowBtn.nextElementSibling.style.display = 'none';
          domArrowBtn.textContent = '▼';
        } else {
          domDropdown.classList.add('open');
          domArrowBtn.nextElementSibling.style.display = 'block';
          domArrowBtn.textContent = '▲';
        }

      });

    });
    
  }

  let domModals = document.getElementsByClassName('modal');
  Array.prototype.forEach.call(domModals, (domModal) => {
    domModal.addEventListener('click', (event) => {
      if (event.target.classList.contains('__modal') ||
        event.target.classList.contains('__close')) {
        domModal.style.display = 'none';
      }
    });
  });

  let domButtons = document.querySelectorAll('button[data-toggle="modal"]');
  Array.prototype.forEach.call(domButtons, (domButton) => {
    domButton.addEventListener('click', () => {
      let target = domButton.getAttribute('data-target');
      if (!target) return false;

      document.querySelector(target).style.display = 'block';
    });
  });

  let domAnchorTabs = document.querySelectorAll('a[role="tab"]');
  Array.prototype.forEach.call(domAnchorTabs, (domAnchorTab) => {
    if (isTouch) {

      domAnchorTab.parentElement.parentElement.style.display = 'none';

      let tabPanelCurrent = document.querySelector(domAnchorTab.getAttribute('href'));
      let tabPanels = tabPanelCurrent.parentElement.children;
      Array.prototype.forEach.call(tabPanels, tabPanel => {
        tabPanel.style.display = 'block';
      });

    } else {

      domAnchorTab.addEventListener('click', (event) => {
        event.preventDefault();

        if (domAnchorTab.classList.contains('active')) return false;

        let domTabs = domAnchorTab.parentElement.parentElement.children;
        Array.prototype.forEach.call(domTabs, domTabLi => {
          let anchor = domTabLi.firstElementChild;
          if (anchor.classList.contains('active')) {
            anchor.classList.remove('active');
          } else {
            anchor.classList.add('active');
          }
        });

        let tabPanelCurrent = document.querySelector(domAnchorTab.getAttribute('href'));
        let tabPanels = tabPanelCurrent.parentElement.children;
        Array.prototype.forEach.call(tabPanels, tabPanel => {
          tabPanel.style.display = (tabPanel === tabPanelCurrent) ? 'block' : 'none';
        });
      });

    }
  });

  let domPrices = document.querySelectorAll('.__price');
  Array.prototype.forEach.call(domPrices, (domPrice) => {
    if (!REHAU) return false;
    domPrice.textContent = Math.floor(Number(parseFloat(domPrice.textContent) * REHAU)) + ' грн.';
  });

  /*let domInputs = document.querySelectorAll('#savings input:not(:disabled), #savings select:not(:disabled)');
   Array.prototype.forEach.call(domInputs, (domInput) => {
   domInput.addEventListener('input', (event) => {
   if (!event.target.value) return false;

   console.log(event.target.value);

   });
   });*/
  let domSavings = document.getElementById('savings');
  if (domSavings) {

    let oDOM = {
      "b_type": document.getElementById('base__type'),
      "b_length": document.getElementById('base__length'),
      "b_width": document.getElementById('base__width'),
      "b_height": document.getElementById('base__height'),
      "b_factor": document.getElementById('base__factor'),
      "b_outdoor": document.getElementById('base__temp_outdoor'),
      "b_indoor": document.getElementById('base__temp_indoor'),
      "b_zone": document.getElementById('base__zone'),

      "b_elec": document.getElementById('base__elec'),
      "b_gas": document.getElementById('base__gas'),

      "b_area": document.getElementById('base__area'),
      "b_walls": document.getElementById('base__walls'),
      "b_basement": document.getElementById('base__basement'),
      "b_attic": document.getElementById('base__attic'),
      "b_wd": document.getElementById('base__wd'),


      "w1_type": document.getElementById('window_1__type'),

      "w1_walls_1": document.getElementById('window_1__walls_1'),
      "w1_basement_1": document.getElementById('window_1__basement_1'),
      "w1_attic_1": document.getElementById('window_1__attic_1'),
      "w1_wd_1": document.getElementById('window_1__wd_1'),
      "w1_summ_1": document.getElementById('window_1__summ_1'),

      "w1_walls_2": document.getElementById('window_1__walls_2'),
      "w1_basement_2": document.getElementById('window_1__basement_2'),
      "w1_attic_2": document.getElementById('window_1__attic_2'),
      "w1_wd_2": document.getElementById('window_1__wd_2'),
      "w1_summ_2": document.getElementById('window_1__summ_2'),

      "w1_walls_3": document.getElementById('window_1__walls_3'),
      "w1_basement_3": document.getElementById('window_1__basement_3'),
      "w1_attic_3": document.getElementById('window_1__attic_3'),
      "w1_wd_3": document.getElementById('window_1__wd_3'),
      "w1_summ_3": document.getElementById('window_1__summ_3'),

      "w1_elec_loss_d": document.getElementById('window_1__elec_loss_d'),
      "w1_elec_loss_m": document.getElementById('window_1__elec_loss_m'),
      "w1_elec_cost_d": document.getElementById('window_1__elec_cost_d'),
      "w1_elec_cost_m": document.getElementById('window_1__elec_cost_m'),

      "w1_gas_loss_d": document.getElementById('window_1__gas_loss_d'),
      "w1_gas_loss_m": document.getElementById('window_1__gas_loss_m'),
      "w1_gas_cost_d": document.getElementById('window_1__gas_cost_d'),
      "w1_gas_cost_m": document.getElementById('window_1__gas_cost_m'),


      "w2_type": document.getElementById('window_2__type'),

      "w2_walls_1": document.getElementById('window_2__walls_1'),
      "w2_basement_1": document.getElementById('window_2__basement_1'),
      "w2_attic_1": document.getElementById('window_2__attic_1'),
      "w2_wd_1": document.getElementById('window_2__wd_1'),
      "w2_summ_1": document.getElementById('window_2__summ_1'),

      "w2_walls_2": document.getElementById('window_2__walls_2'),
      "w2_basement_2": document.getElementById('window_2__basement_2'),
      "w2_attic_2": document.getElementById('window_2__attic_2'),
      "w2_wd_2": document.getElementById('window_2__wd_2'),
      "w2_summ_2": document.getElementById('window_2__summ_2'),

      "w2_walls_3": document.getElementById('window_2__walls_3'),
      "w2_basement_3": document.getElementById('window_2__basement_3'),
      "w2_attic_3": document.getElementById('window_2__attic_3'),
      "w2_wd_3": document.getElementById('window_2__wd_3'),
      "w2_summ_3": document.getElementById('window_2__summ_3'),

      "w2_elec_loss_d": document.getElementById('window_2__elec_loss_d'),
      "w2_elec_loss_m": document.getElementById('window_2__elec_loss_m'),
      "w2_elec_cost_d": document.getElementById('window_2__elec_cost_d'),
      "w2_elec_cost_m": document.getElementById('window_2__elec_cost_m'),

      "w2_gas_loss_d": document.getElementById('window_2__gas_loss_d'),
      "w2_gas_loss_m": document.getElementById('window_2__gas_loss_m'),
      "w2_gas_cost_d": document.getElementById('window_2__gas_cost_d'),
      "w2_gas_cost_m": document.getElementById('window_2__gas_cost_m'),


      "elect_m1": document.getElementById('result_elect_1m'),
      "elect_y1": document.getElementById('result_elect_1y'),
      "elect_y5": document.getElementById('result_elect_5y'),
      "elect_y15": document.getElementById('result_elect_15y'),
      "elect_y25": document.getElementById('result_elect_25y'),
      "elect_percent": document.getElementById('result_elect_percent'),

      "gas_m1": document.getElementById('result_gas_1m'),
      "gas_y1": document.getElementById('result_gas_1y'),
      "gas_y5": document.getElementById('result_gas_5y'),
      "gas_y15": document.getElementById('result_gas_15y'),
      "gas_y25": document.getElementById('result_gas_25y'),
      "gas_percent": document.getElementById('result_gas_percent'),

      "combi_m1": document.getElementById('result_combi_1m'),
      "combi_y1": document.getElementById('result_combi_1y'),
      "combi_y5": document.getElementById('result_combi_5y'),
      "combi_y15": document.getElementById('result_combi_15y'),
      "combi_y25": document.getElementById('result_combi_25y')
    };

    let v = function(dom){
      let val = (oDOM[dom].value) ? oDOM[dom].value : oDOM[dom].textContent;
      return Math.round(val * 100) / 100;
    };

    let set = function(dom, val) {
      if (oDOM[dom].value) {
        oDOM[dom].value = Math.round(val * 100) / 100;
      } else {
        oDOM[dom].textContent = Math.round(val * 100) / 100;
      }
    };

    let chart = Highcharts.chart('chart', {
      chart: {
        type: 'line'
      },
      title: {
        text: 'Экономия с окнами от «Роял Пласт»'
      },
      xAxis: {
        categories: ['1 мес.', '1 год', '5 лет', '15 лет', '25 лет']
      },
      yAxis: {
        title: {
          text: 'Экономия, грн.'
        }
      },
      plotOptions: {
        line: {
          enableMouseTracking: false
        }
      },
      series: [{
        name: 'Электрическое',
        data: [v('elect_m1'), v('elect_y1'), v('elect_y5'), v('elect_y15'), v('elect_y25')]
      }, {
        name: 'Газовое',
        data: [v('gas_m1'), v('gas_y1'), v('gas_y5'), v('gas_y15'), v('gas_y25')]
      }, {
        name: 'Комбинированное',
        data: [v('combi_m1'), v('combi_y1'), v('combi_y5'), v('combi_y15'), v('combi_y25')]
      }]
    });

    let calculate = function() {

      set('b_area',
        v('b_length') * v('b_width')
      );

      if (v('b_type') == '1') {
        set('b_walls',
          2 * v('b_length') * v('b_height') + 2 * v('b_width') * v('b_height')
        );
      } else {
        set('b_walls',
          ( 2 * v('b_length') * v('b_height') + 2 * v('b_width') * v('b_height') ) / 2
        );
      }

      set('b_basement',
        v('b_length') * v('b_width') * v('b_type')
      );

      set('b_attic',
        v('b_length') * v('b_width') * v('b_type')
      );

      set('b_wd',
        v('b_length') * v('b_width') * v('b_factor')
      );

      set('w1_walls_1',
        ( v('b_zone') == '1' ) ? 3.3 : 2.8 //С.6 ДБН В.2.6-31:2006
      );
      set('w1_basement_1',
        ( v('b_zone') == '1' ) ? 3.75 : 3.3 //С.6 ДБН В.2.6-31:2006
      );
      set('w1_attic_1',
        ( v('b_zone') == '1' ) ? 4.95 : 4.5 //С.6 ДБН В.2.6-31:2006
      );
      set('w1_wd_1',
        v('w1_type')
      );
      set('w1_summ_1',
        ( v('b_walls') + v('b_basement') + v('b_attic') + v('b_wd') ) / ( ( v('b_walls') / v('w1_walls_1') ) + ( v('b_basement') / v('w1_basement_1') ) + ( v('b_attic') / v('w1_attic_1') ) + ( v('b_wd') / v('w1_wd_1') ) )
      );

      set('w1_walls_2',
        ( v('b_indoor') - v('b_outdoor') ) / v('w1_walls_1') * v('b_walls')
      );
      set('w1_basement_2',
        ( v('b_indoor') - v('b_outdoor') ) / v('w1_basement_1') * v('b_basement')
      );
      set('w1_attic_2',
        ( v('b_indoor') - v('b_outdoor') ) / v('w1_attic_1') * v('b_attic')
      );
      set('w1_wd_2',
        ( v('b_indoor') - v('b_outdoor') ) / v('w1_wd_1') * v('b_wd')
      );
      set('w1_summ_2',
        v('w1_walls_2') + v('w1_basement_2') + v('w1_attic_2') + v('w1_wd_2')
      );

      set('w1_walls_3',
        v('w1_walls_2') * 100 / v('w1_summ_2')
      );
      set('w1_basement_3',
        v('w1_basement_2') * 100 / v('w1_summ_2')
      );
      set('w1_attic_3',
        v('w1_attic_2') * 100 / v('w1_summ_2')
      );
      set('w1_wd_3',
        v('w1_wd_2') * 100 / v('w1_summ_2')
      );
      set('w1_summ_3',
        v('w1_walls_3') + v('w1_basement_3') + v('w1_attic_3') + v('w1_wd_3')
      );

      set('w1_elec_loss_d',
        v('w1_summ_2') * 24 / 1000
      );
      set('w1_elec_loss_m',
        v('w1_elec_loss_d') * 31
      );
      set('w1_elec_cost_d',
        v('w1_elec_loss_d') * v('b_elec')
      );
      /*set('w1_elec_cost_m',
       v('w1_elec_cost_d') * 31
       );*/
      set('w1_elec_cost_m',
        150 * v('b_elec') + ( 400 - 150 ) * v('b_elec') + ( v('w1_elec_loss_m') - 400 ) * 0.92 //???
      );

      /*set('w1_gas_loss_d',
       v('w1_summ_2') * 24 / 1000
       );*/
      set('w1_gas_loss_d',
        v('w1_elec_loss_d') * 0.1 //???
      );
      /*set('w1_gas_loss_m',
       v('w1_gas_loss_d') * 31
       );*/
      set('w1_gas_loss_m',
        v('w1_elec_loss_m') * 0.1 //???
      );
      set('w1_gas_cost_d',
        v('w1_gas_loss_d') * v('b_gas')
      );
      set('w1_gas_cost_m',
        v('w1_gas_cost_d') * 31
      );


      set('w2_walls_1',
        ( v('b_zone') == '1' ) ? 3.3 : 2.8 //С.6 ДБН В.2.6-31:2006
      );
      set('w2_basement_1',
        ( v('b_zone') == '1' ) ? 3.75 : 3.3 //С.6 ДБН В.2.6-31:2006
      );
      set('w2_attic_1',
        ( v('b_zone') == '1' ) ? 4.95 : 4.5 //С.6 ДБН В.2.6-31:2006
      );
      set('w2_wd_1',
        v('w2_type')
      );
      set('w2_summ_1',
        ( v('b_walls') + v('b_basement') + v('b_attic') + v('b_wd') ) / ( ( v('b_walls') / v('w2_walls_1') ) + ( v('b_basement') / v('w2_basement_1') ) + ( v('b_attic') / v('w2_attic_1') ) + ( v('b_wd') / v('w2_wd_1') ) )
      );
      set('w2_walls_2',
        ( v('b_indoor') - v('b_outdoor') ) / v('w2_walls_1') * v('b_walls')
      );

      set('w2_basement_2',
        ( v('b_indoor') - v('b_outdoor') ) / v('w2_basement_1') * v('b_basement')
      );
      set('w2_attic_2',
        ( v('b_indoor') - v('b_outdoor') ) / v('w2_attic_1') * v('b_attic')
      );
      set('w2_wd_2',
        ( v('b_indoor') - v('b_outdoor') ) / v('w2_wd_1') * v('b_wd')
      );
      set('w2_summ_2',
        v('w2_walls_2') + v('w2_basement_2') + v('w2_attic_2') + v('w2_wd_2')
      );

      set('w2_walls_3',
        v('w2_walls_2') * 100 / v('w2_summ_2')
      );
      set('w2_basement_3',
        v('w2_basement_2') * 100 / v('w2_summ_2')
      );
      set('w2_attic_3',
        v('w2_attic_2') * 100 / v('w2_summ_2')
      );
      set('w2_wd_3',
        v('w2_wd_2') * 100 / v('w2_summ_2')
      );
      set('w2_summ_3',
        v('w2_walls_3') + v('w2_basement_3') + v('w2_attic_3') + v('w2_wd_3')
      );

      set('w2_elec_loss_d',
        v('w2_summ_2') * 24 / 1000
      );
      set('w2_elec_loss_m',
        v('w2_elec_loss_d') * 31
      );
      set('w2_elec_cost_d',
        v('w2_elec_loss_d') * v('b_elec')
      );
      /*set('w2_elec_cost_m',
       v('w2_elec_cost_d') * 31
       );*/
      set('w2_elec_cost_m',
        150 * v('b_elec') + ( 400 - 150 ) * v('b_elec') + ( v('w2_elec_loss_m') - 400 ) * 0.92 //???
      );

      /*set('w2_gas_loss_d',
       v('w2_summ_2') * 24 / 1000
       );*/
      set('w2_gas_loss_d',
        v('w2_elec_loss_d') * 0.1 //???
      );
      /*set('w2_gas_loss_m',
       v('w2_gas_loss_d') * 31
       );*/
      set('w2_gas_loss_m',
        v('w2_elec_loss_m') * 0.1 //???
      );
      set('w2_gas_cost_d',
        v('w2_gas_loss_d') * v('b_gas')
      );
      set('w2_gas_cost_m',
        v('w2_gas_cost_d') * 31
      );


      set('elect_m1',
        v('w1_elec_cost_m') - v('w2_elec_cost_m')
      );
      /*set('elect_y1',
       v('elect_m1') * 12
       );*/
      set('elect_y1',
        v('elect_m1') * 6 //???
      );
      set('elect_y5',
        v('elect_y1') * 5
      );
      set('elect_y15',
        v('elect_y1') * 15
      );
      set('elect_y25',
        v('elect_y1') * 25
      );
      set('elect_percent',
        v('elect_m1') * 100 / v('w1_elec_cost_m')
      );

      set('gas_m1',
        v('w1_gas_cost_m') - v('w2_gas_cost_m')
      );
      /*set('gas_y1',
       v('gas_m1') * 12
       );*/
      set('gas_y1',
        v('gas_m1') * 6 //???
      );
      set('gas_y5',
        v('gas_y1') * 5
      );
      set('gas_y15',
        v('gas_y1') * 15
      );
      set('gas_y25',
        v('gas_y1') * 25
      );
      set('gas_percent',
        v('gas_m1') * 100 / v('w1_gas_cost_m')
      );

      set('combi_m1',
        v('gas_m1') + ( v('elect_m1') / 2 * 0.2 )
      );
      /*set('combi_y1',
       v('combi_m1') * 12
       );*/
      set('combi_y1',
        v('combi_m1') * 6 //???
      );
      set('combi_y5',
        v('combi_y1') * 5
      );
      set('combi_y15',
        v('combi_y1') * 15
      );
      set('combi_y25',
        v('combi_y1') * 25
      );

      chart.series[0].setData([ v('elect_m1'), v('elect_y1'), v('elect_y5'), v('elect_y15'), v('elect_y25') ]);
      chart.series[1].setData([ v('gas_m1'), v('gas_y1'), v('gas_y5'), v('gas_y15'), v('gas_y25') ]);
      chart.series[2].setData([ v('combi_m1'), v('combi_y1'), v('combi_y5'), v('combi_y15'), v('combi_y25') ]);

    };

    domSavings.addEventListener('input', (event) => {
      if (event.target.matches('input:not(:disabled), select:not(:disabled)') && event.target.value) {
        calculate();
      }
    });

    calculate();

  }

}

document.addEventListener("DOMContentLoaded", ready);
